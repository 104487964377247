import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import go_back_image from '../../images/btn-back.svg'

const GoBackStyledButton = styled.button.attrs((props) => ({
  type: 'button',
}))`
  position: ${(props) => (props.position ? props.position : 'absolute')};
  top: ${(props) => (props.top ? props.top : '20px')};
  left: ${(props) => (props.left ? props.left : '20px')};
  background-image: url(${go_back_image});
  z-index: 9999;
  width: 30px;
  height: 30px;
  border-radius: 15px;
`

const onGoBackButtonClick = () => {
  navigate(-1)
}

const GoBackButton = (props) => {
  return (
    <GoBackStyledButton
      {...props}
      onClick={onGoBackButtonClick}
    ></GoBackStyledButton>
  )
}

export default GoBackButton
