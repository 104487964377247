import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import media from '../../components/css/media'
import GoBackButton from '../../components/common/goBackButton'

const Content = styled.div`
  padding: 2rem;
  position: relative;
  background-color: #fff;

  ${media.desktop`
        padding: 3rem;
    `};
`

const PromoTitle = styled.h1`
  color: #000;
  font-size: 2em;
  text-transform: uppercase;
  margin: 0;

  ${media.desktop`
    font-size: 3em;
  `};
`

const PromoTag = styled.span`
  color: #fff;
  font-size: 0.75em;
  line-height: 30px;
  text-transform: uppercase;
  border-radius: 2px;
  bottom: 1rem;
  padding: 0 0.5rem 0.1rem;
  text-align: center;

  ${(props) => {
    switch (props.bg.replace(' ', '-')) {
      case 'casino':
      default:
        return `
                background-color: #6bc89b;
                `
      case 'live-casino':
        return `
                background-color: #900000;
                `
      case 'sports':
        return `
                background-color: #218afb;
                `
      case 'virtual-sports':
        return `
                background-color: #fad749;
                `
    }
  }}
`

const PromoContent = styled.div`
  padding: 2rem 0 0 0;
  line-height: 1.3;

  b {
    font-weight: 600;
  }
`

const TermsWrapper = styled.div`
  background-color: #eee;
  margin: 3rem 0 0 0;
`

const TermsHeader = styled.div`
  background-color: #ddd;
  padding: 0 1rem;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
`

const TermsContent = styled.div`
  padding: 1rem;
  font-size: 0.8em;
`

const PromoCallToAction = styled(Link)`
  text-decoration: none;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #31c27c;
  color: #fff;
  border-radius: 4px;
  padding: 0.6rem 2rem;
  line-height: 1.8;

  &:hover {
    text-decoration: none;
  }
`

const PromotionPage = ({ pageContext: { promotion } }) => {
  return (
    <SplitLayout img={promotion.image}>
      <Seo title={`${promotion.title}`} />
      <GoBackButton top='60px'></GoBackButton>
      <Content>
        <PromoTitle>{promotion.title}</PromoTitle>
        <PromoTag bg={promotion.type}>{promotion.type}</PromoTag>
        <PromoContent dangerouslySetInnerHTML={{ __html: promotion.content }} />
        <PromoCallToAction
          to={`${promotion.cta.url}`}
          title={`${promotion.cta.title}`}
        >
          {promotion.cta.title}
        </PromoCallToAction>
        <TermsWrapper>
          <TermsHeader>{promotion.tandc_title}</TermsHeader>
          <TermsContent dangerouslySetInnerHTML={{ __html: promotion.tandc }} />
        </TermsWrapper>
      </Content>
    </SplitLayout>
  )
}

export default PromotionPage
